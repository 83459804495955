import { useSelector } from "react-redux";
import { useIsMobile } from "./ui";
import { RootState } from "../types/state";
import { IS_RN_WEBVIEW, USER_INSTARAISE_APP_VERSION } from "../../lib";

export function useAddInstagram() {
  const isMobile = useIsMobile();
  const instagramImage = useSelector(
    ({ campaign }: RootState) => campaign.instagramImage,
  );

  // instagram is only supported on the app from v1.0.5 and greater
  if (
    IS_RN_WEBVIEW &&
    (!USER_INSTARAISE_APP_VERSION || USER_INSTARAISE_APP_VERSION < "1.0.5")
  ) {
    return false;
  }

  return Boolean(isMobile && instagramImage);
}
